import IconMapping from '@/components/utils/iconMapping';
import { EntityType } from '@/models/entity-type/EntityType';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface AccordionCardProps {
  className?: string;
  items: {
    title: string;
    image: string;
    description: string;
    url: string;
    type: EntityType.AGRITURISMI | EntityType.CANTINE | EntityType.FRANTOI | EntityType.RISTORANTI;
  }[];
}

const AccordionCard = ({ items, className }: AccordionCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'grid w-full grid-cols-1 gap-4 overflow-hidden rounded-xl bg-transparent',
        'sm:grid-cols-2',
        'lg:flex lg:h-[500px] lg:gap-0',
        className,
      )}
    >
      {items?.map((item, index) => {
        const IconComponent = IconMapping[item?.type].bold;

        return (
          <div
            key={item.title + index}
            className={clsx(
              'group relative aspect-video w-full duration-500 ease-out',
              'lg:aspect-auto lg:w-1/4 lg:hover:w-2/3',
              item?.type,
            )}
          >
            {/* Card */}
            <Link
              href={item?.url ?? '/'}
              className={clsx(
                'shadow-card relative block h-full w-full overflow-hidden rounded-md',
                'lg:h-full lg:rounded-none lg:shadow-none',
              )}
            >
              <Image
                blurDataURL="/placeholder.png"
                placeholder="blur"
                src={item.image ?? 'https://picsum.photos/900'}
                alt={'_calabria_terrabuona'}
                fill
                className="object-cover"
              />
              <div
                className={clsx(
                  'absolute inset-0 z-10 flex flex-col items-start justify-end border-l-[6px] border-primary-500 p-4',
                  'bg-gradient-to-t from-black/80 to-transparent to-30%',
                )}
              >
                <h3 className="text-3xl text-white">{t(item.title)}</h3>
                <div className="grid grid-rows-[0fr] overflow-hidden transition-all duration-500 group-hover:grid-rows-[1fr]">
                  <p className="line-clamp-2 min-h-0 overflow-hidden text-sm text-white transition-all duration-500 group-hover:animate-slideInUp">
                    {t(item.description)}
                  </p>
                </div>
              </div>
            </Link>

            {/* Icon */}
            <div className={clsx('absolute left-4 top-4 rounded-full bg-primary-500 p-2 lg:p-3', '')}>
              <IconComponent className="h-9 w-9 text-white lg:h-12 lg:w-12" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(AccordionCard);
