import bgHome from '@/assets/images/bg/bg-home.png';
import { EventCard, Layout, Meta } from '@/components';
import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import AccordionCard from '@/components/shared/cards/AccordionCard';
import PrimaryCard from '@/components/shared/cards/PrimaryCard';
import CustomSwiper from '@/components/shared/custom-swiper/CustomSwiper';
import SectionTitleContent from '@/components/shared/sections/SectionTitleContent';
import { filieraData } from '@/data/filiera/filiera';
import useBreakPoint from '@/hook/useBreakPoint';
import useHeaderContainer from '@/hook/useHeaderContainer';
import { PageApi } from '@/lib/api/PageApi';
import { IndexBody } from '@/models/interfaces/pages/bodies';
import { NextPageData, RootPage } from '@/models/interfaces/pages/root';
import { EventCardType } from '@/models/types/types';
import clsx from 'clsx';
import { NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import Error from './_error';

export const getStaticProps = async ({ locale }: { locale: string }) => {
  const res = await PageApi.getHomePage<RootPage<IndexBody> & { error: number; statusText: string }>(locale);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      res,
      error: res?.error ?? null,
    },
    revalidate: 10,
  };
};

const DynamicGal = dynamic(() => import('@/components/shared/gal/GalSection'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const DynamicSwiperWrapper = dynamic(() => import('@/components/shared/sections/SwiperWrapper'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const Home: NextPage<NextPageData<RootPage<IndexBody>>> = ({ res, error, preview, previewData }) => {
  const [heightMegaCard, setHeightMegaCard] = useState('');

  const { body, lang, menu, meta } = res ?? {};

  const { events, gal, itineraries, products } = body ?? {};

  const { t, i18n } = useTranslation();

  const { greaterThanLg, greaterThan2Xl } = useBreakPoint();

  const { ref } = useHeaderContainer();

  const calcHeightMegaCard = useCallback(() => {
    if (!greaterThanLg) {
      return setHeightMegaCard('');
    }

    if (ref?.current) {
      const { height } = ref.current.getBoundingClientRect();

      let heightRem = height / 16;

      if (greaterThan2Xl) {
        heightRem = heightRem + 6;
      } else if (greaterThanLg) {
        heightRem = heightRem + 3;
      }

      setHeightMegaCard(`${heightRem}rem`);
    }
  }, [greaterThanLg, greaterThan2Xl, ref]);

  useEffect(() => {
    calcHeightMegaCard();
    window.addEventListener('resize', calcHeightMegaCard);

    return () => {
      window.removeEventListener('resize', calcHeightMegaCard);
    };
  }, [greaterThanLg, greaterThan2Xl, calcHeightMegaCard]);

  if (error) {
    return <Error statusCode={error} statusMessage={'Errore'} />;
  }

  return (
    <Layout changeHeaderOnScroll menu={menu}>
      <Meta meta={meta} lang={lang} />
      <div className="flex flex-col gap-20 pb-10 lg:p-6 xl:gap-28 2xl:gap-32 2xl:p-12">
        {/* Mega Card Home */}
        <div
          className={clsx(
            'relative mx-auto h-full max-h-[50rem] min-h-[40rem] w-full overflow-hidden transition-all duration-500',
            'sm:lg:max-h-[60rem]',
            'md:min-h-[45rem]',
            'lg:max-h-full lg:min-h-[55rem] lg:rounded-[2rem]',
            '3xl:w-4/5 3xl:max-w-[1920px]',
          )}
          style={{
            height: heightMegaCard ? `calc(100vh - ${heightMegaCard})` : 'calc(100vh - 5rem)',
          }}
        >
          <div className={clsx('absolute inset-0 animate-fadeIn')}>
            <Image
              blurDataURL="/placeholder.png"
              placeholder="blur"
              src={bgHome}
              alt="bg_home"
              className="object-cover"
              priority
              fill
            />
            <div
              className={clsx(
                'absolute inset-0 bg-gradient-to-t from-light from-10% via-transparent via-40% to-transparent opacity-100 transition-all duration-300 dark:from-dark',
                'lg:bg-black/0 lg:from-transparent lg:dark:from-transparent',
              )}
            />
          </div>

          <div className="relative z-10 flex h-full w-full items-center justify-center">
            <div
              className={clsx(
                'flex h-full w-full flex-col justify-end gap-12 text-center',
                'lg:justify-between lg:gap-8 lg:px-20 lg:py-14',
                '2xl:px-32 2xl:py-24',
              )}
            >
              <div
                className={clsx(
                  'container-p lg:container-p-0 ml-auto flex h-full flex-col justify-center text-right',
                  'lg:h-full lg:max-w-[70%]',
                  '2xl:max-w-[50%]',
                )}
              >
                <h1 className="text-drop-shadow text-white">{t('Il cuore fertile del mediterraneo')}</h1>
                {/* <p className=" text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                </p> */}
              </div>

              <div
                className={clsx(
                  'relative flex w-full flex-col items-start justify-start gap-4',
                  'lg:items-start lg:justify-end lg:gap-6',
                )}
              >
                <h2 className="container-p lg:container-p-0 text-drop-shadow text-left text-white xl:mt-auto">
                  {t('Ogni paesaggio ha il suo sapore')}
                </h2>
                <div
                  className={clsx(
                    'lg:dark-none relative flex h-[360px] w-full max-w-full gap-6',
                    'md:h-[400px]',
                    'lg:h-[400px]',
                    '2xl:h-[450px]',
                  )}
                >
                  <CustomSwiper
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    mousewheel={true}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    scrollbar={true}
                    classNamesSwiperSlides={clsx(
                      'ml-2.5 last:mr-2.5',
                      'sm:ml-4 sm:last:mr-4',
                      'md:ml-8 md:last:mr-8',
                      'lg:first:ml-0 lg:ml-4 lg:last:mr-0',
                    )}
                  >
                    {itineraries?.children?.map((product, index) => {
                      const priority = index < 4;

                      return (
                        <PrimaryCard
                          key={product?.id}
                          item={{
                            id: String(product?.id) || '',
                            title: product?.title || '',
                            image: product?.image || '',
                            address: product?.address || '',
                            category: product?.category || '',
                            subtitle: product?.subtitle || '',
                            description: product?.description || '',
                            url: product?.url || '',
                            permalink: product?.permalink || '',
                          }}
                          priority={priority}
                          className="h-[93%] w-56 xl:w-60 2xl:w-80"
                        />
                      );
                    })}
                  </CustomSwiper>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Esplora il territorio */}
        <DynamicGal gal={gal} />

        {/* La Filiera */}
        <SectionTitleContent title={t('Operatori').toString()} className="container">
          <AccordionCard
            items={filieraData.map((item) => {
              return {
                ...item,
                url: item.url[i18n.language],
              };
            })}
            className="lg:shadow-card lg:h-[600px]"
          />
        </SectionTitleContent>

        {/* Prodotti autentici */}
        <SectionTitleContent
          title={t('Prodotti autentici').toString()}
          url={{
            label: t('Visualizza tutti').toString(),
            link: products?.archiveURL || '/',
          }}
          className="container"
          show={products?.children?.length > 0}
        >
          <DynamicSwiperWrapper>
            {products?.children?.map((product, index) => (
              <PrimaryCard
                key={product?.id}
                item={{
                  id: String(product?.id) || '',
                  title: product?.title || '',
                  image: product?.image || '',
                  address: product?.address || '',
                  category: product?.category || '',
                  subtitle: product?.subtitle || '',
                  description: product?.description || '',
                  url: product?.url || '',
                  permalink: product?.permalink || '',
                }}
                className="h-[93%] w-56 xl:w-60 2xl:w-80"
              />
            ))}
          </DynamicSwiperWrapper>
        </SectionTitleContent>

        {/* Eventi */}
        <SectionTitleContent
          title={t('Eventi').toString()}
          url={{
            label: t('Visualizza tutti').toString(),
            link: events?.archiveURL || '/',
          }}
          className="container"
          show={events?.children?.length > 0}
        >
          <div className={clsx('grid grid-cols-1 gap-5 ', 'sm:grid-cols-2', 'lg:grid-cols-4')}>
            {events?.children?.map((item, index) => {
              const colSpan = index === 2 || index === 3 ? 'lg:col-span-2' : 'lg:col-span-1';

              return (
                <div key={item?.id} className={clsx('h-72 w-full ', 'md:h-80 ', 'lg:h-96', colSpan)}>
                  <EventCard item={item as Partial<EventCardType>} />
                </div>
              );
            })}
          </div>
        </SectionTitleContent>
      </div>
    </Layout>
  );
};

export default Home;
