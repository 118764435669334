import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const DynamicAnimation = dynamic(() => import('@/components/shared/utility/animations/Animation404'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const Error404 = () => {
  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const linkHome = language === 'it' ? '/' : `/${language}`;

  return (
    <div>
      <div className="mx-auto max-w-[50%]">
        <DynamicAnimation />
      </div>
      <h2 className="text-2xl font-bold text-gray-700 dark:text-gray-200">{t('Pagina non trovata')}</h2>
      <p className="text-gray-700 dark:text-gray-200">
        {t('Non siamo riusciti a trovare quello che stavi cercando')} 😔
        <br />
        {t('Prova a tornare alla')}{' '}
        <Link href={linkHome} className="text-primary-500 hover:underline">
          {t('home')}
        </Link>
        .
      </p>
    </div>
  );
};

export default Error404;
