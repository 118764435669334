import { useState, useEffect } from "react";

export const useIsDev = () => {
	const [isDev, setIsDev] = useState(false);

	useEffect(() => {
		setIsDev(process.env.NODE_ENV === "development");
	}, []);

	return { isDev };
};
