import { EntityType } from '../../models/entity-type/EntityType';
import cantineImg from '../../assets/images/filiera/Cantine.jpg';
import ristorantiImg from '../../assets/images/filiera/Ristoranti.jpg';
import agriturismiImg from '../../assets/images/filiera/Agriturismi.jpg';
import frantoiImg from '../../assets/images/filiera/Frantoi.jpg';

interface FilieraData {
  title: string;
  description: string;
  image: string;
  url: Record<string, string>;
  type: EntityType.AGRITURISMI | EntityType.CANTINE | EntityType.FRANTOI | EntityType.RISTORANTI;
}

export const filieraData: FilieraData[] = [
  {
    title: 'Cantine',
    description: 'Scoprire e gustare vini fatti da tempo, sapienza e bellezza',
    image: cantineImg.src,
    url: { it: '/cantine', en: '/wineries' },
    type: EntityType.CANTINE,
  },
  {
    title: 'Frantoi',
    description: 'I luoghi dell’arte millenaria dell’olio',
    image: frantoiImg.src,
    url: { it: '/frantoi', en: '/oil-mills' },
    type: EntityType.FRANTOI,
  },
  {
    title: 'Ristoranti',
    description: 'Dove il gusto si trasforma in indimenticabili esperienze',
    image: ristorantiImg.src,
    url: { it: '/ristorazione', en: '/cuisine' },
    type: EntityType.RISTORANTI,
  },

  {
    title: 'Agriturismi',
    description: 'Nature, relax, buon cibo e tradizione',
    image: agriturismiImg.src,
    url: { it: '/agriturismi', en: '/farmhouses' },
    type: EntityType.AGRITURISMI,
  },
];
