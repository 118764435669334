import { Swiper as SwiperUI, SwiperSlide, SwiperProps, SwiperClass } from 'swiper/react';
import { Children, FC, memo, useEffect, useMemo, useRef, useState } from 'react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';

// custom css
import { Pagination, Mousewheel, Navigation, Scrollbar, Autoplay } from 'swiper';
import clsx from 'clsx';
import SwiperNavigationButton from './components/SwiperNavigationButton';

interface CustomSwiperProps extends SwiperProps {
  children: React.ReactNode | React.ReactNode[];
  navigation?: boolean;
  pagination?: boolean;
  scrollbar?: boolean;
  classNamesSwiperSlides?: string;
  otherChilren?: React.ReactNode;
  selectedIndex?: number;
  onSelectedIndexChange?: (index: number) => void;
}

const CustomSwiper: FC<CustomSwiperProps> = ({
  children,
  navigation = false,
  pagination = false,
  scrollbar = false,
  classNamesSwiperSlides = '',
  otherChilren,
  selectedIndex,
  onSelectedIndexChange,
  ...props
}: CustomSwiperProps) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const refSwiper = useRef<any>(null);

  const modules = useMemo(() => {
    const modules = [];

    navigation && modules.push(Navigation);
    pagination && modules.push(Pagination);
    scrollbar && modules.push(Scrollbar);
    props.autoplay && modules.push(Autoplay);
    modules.push(Mousewheel);

    return modules;
  }, [props.autoplay, navigation, pagination, scrollbar]);

  const onChangeActiveIndex = (swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    onSelectedIndexChange?.(swiper.activeIndex);
  };

  useEffect(() => {
    if (refSwiper && selectedIndex !== undefined && selectedIndex !== -1) {
      refSwiper?.current?.slideTo(selectedIndex);
    }
  }, [refSwiper, selectedIndex]);

  return (
    <div className="main-wrap h-full w-full">
      <SwiperUI
        {...props}
        onSwiper={(swiper) => {
          if (!refSwiper.current) refSwiper.current = swiper;
        }}
        modules={modules}
        pagination={{ clickable: true }}
        direction="horizontal"
        className={clsx(
          'group/swiper relative flex h-full w-full items-center justify-center',
          props.className,
        )}
        grabCursor={true}
        mousewheel={{
          forceToAxis: true,
        }}
        scrollbar={
          scrollbar
            ? {
                draggable: true,
                dragSize: 150,
                snapOnRelease: true,
                dragClass: 'swiper-scrollbar-drag',
              }
            : false
        }
        onActiveIndexChange={onChangeActiveIndex}
      >
        {otherChilren}
        {navigation ? (
          <SwiperNavigationButton direction="prev" isBeginning={isBeginning} isEnd={isEnd} />
        ) : null}

        {Children.map(children, (child) => {
          return (
            <SwiperSlide
              className={clsx('!active:cursor-grabbing !w-fit !cursor-grab', classNamesSwiperSlides)}
            >
              {({ isActive }) => child}
            </SwiperSlide>
          );
        })}

        {navigation ? (
          <SwiperNavigationButton direction="next" isBeginning={isBeginning} isEnd={isEnd} />
        ) : null}

        {/* <SwiperPagination /> */}
      </SwiperUI>
    </div>
  );
};

export default memo(CustomSwiper);
