import * as Sentry from '@sentry/nextjs';
import Error404 from '@/components/shared/utility/errors/Error404';
import Error500 from '@/components/shared/utility/errors/Error500';
import { useEffect } from 'react';
import { useIsDev } from '@/hook/useIsDev';

const componentErrorMapping: Record<number, () => JSX.Element> = {
  404: Error404,
  500: Error500,
};

const Error = ({ statusCode, statusMessage }: { statusCode: number; statusMessage: string }) => {
  const { isDev } = useIsDev();

  const ComponentError = componentErrorMapping[statusCode] ?? Error500;

  useEffect(() => {
    (async () => {
      if (statusCode && !isDev) await Sentry.captureUnderscoreErrorException({ statusCode });
    })();
  }, [statusCode]);

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-white dark:bg-dark">
      <div className="container">
        <div className="flex items-center gap-2">
          {statusMessage && (
            <h1 className="text-4xl font-bold text-gray-700 dark:text-gray-200">{statusMessage}</h1>
          )}
          {statusCode && (
            <h2 className="text-2xl font-bold text-gray-700 dark:text-gray-200">{statusCode}</h2>
          )}
        </div>
        <ComponentError />
      </div>
    </div>
  );
};

Error.getInitialProps = async ({ res, err }: { res: any; err: any }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return { statusCode };
};

export default Error;
