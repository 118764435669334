import clsx from 'clsx';
import Link from 'next/link';
import React, { FC, memo } from 'react';

interface SectionTitleContentProps {
  title: string;
  children: React.ReactNode;
  className?: string;
  heading?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headingClassName?: string;
  headingWrapperClassName?: string;
  url?: {
    label: string;
    link: string;
    className?: string;
  };
  show?: boolean;
}

const SectionTitleContent: FC<SectionTitleContentProps> = ({
  children,
  title,
  heading = 'h2',
  headingClassName = '',
  headingWrapperClassName = '',
  className,
  url,
  show = true,
}) => {
  const Heading = heading;

  if (!show) return null;

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      <div className={clsx('flex items-end justify-between', headingWrapperClassName)}>
        <Heading className={headingClassName}>{title}</Heading>
        {url?.link ? (
          <div className="hidden justify-end text-sm lg:inline-flex">
            <Link href={url?.link}>{url?.label}</Link>
          </div>
        ) : null}
      </div>

      {children}

      {url?.link ? (
        <div className={clsx('flex w-full justify-end lg:mt-2 lg:hidden xl:mt-9', url?.className)}>
          <Link href={url?.link} className="text-sm">
            {url?.label}
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default memo(SectionTitleContent);
