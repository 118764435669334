import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useSwiper } from 'swiper/react';

interface SwiperNavigationButtonProps {
  direction: 'next' | 'prev';
  isBeginning?: boolean;
  isEnd?: boolean;
}

const SwiperNavigationButton = ({ direction, isBeginning, isEnd }: SwiperNavigationButtonProps) => {
  const swiper = useSwiper();

  const handlePrev = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      swiper.slidePrev();
    },
    [swiper],
  );

  const handleNext = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      swiper.slideNext();
    },
    [swiper],
  );

  if (direction === 'prev') {
    return (
      <button
        onClick={handlePrev}
        className={clsx(
          'text-beige-first absolute left-0 top-1/2 z-50 ml-1.5 -translate-y-1/2 rounded-full border bg-white p-1.5 opacity-0 transition-all duration-300 hover:scale-110 active:scale-100 ',
          {
            'group-hover/swiper:opacity-100': !isBeginning,
          },
        )}
      >
        <ChevronLeftIcon strokeWidth={3} className="h-4 w-4 pr-[2px] text-stone-600" />
      </button>
    );
  } else {
    return (
      <button
        onClick={handleNext}
        className={clsx(
          'text-beige-first absolute right-0 top-1/2 z-50 mr-1.5 -translate-y-1/2 rounded-full border bg-white p-1.5 opacity-0 transition-all duration-300 hover:scale-110 active:scale-100 ',
          {
            'group-hover/swiper:opacity-100': !isEnd,
          },
        )}
      >
        <ChevronRightIcon strokeWidth={3} className="h-4 w-4 pl-[2px] text-stone-600" />
      </button>
    );
  }
};

export default SwiperNavigationButton;
