import React, { useEffect, useRef } from 'react';
import { useCustomDebounce } from './useCustomDebounce';

const useHeaderContainer = (
  callback?: (size: { width: number; height: number }) => void,
  debounce: number = 10,
) => {
  const [headerWidth, setHeaderWidth] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const headerContainer = useRef<HTMLHeadingElement | null>(null);

  const handleHeaderHeight = useCustomDebounce((height: number) => {
    setHeaderHeight(height);
  }, debounce);

  const handleHeaderWidth = useCustomDebounce((width: number) => {
    setHeaderWidth(width);
  }, debounce);

  useEffect(() => {
    if (document) {
      const ref = document.getElementById('header') as HTMLHeadingElement;
      headerContainer.current = ref;

      new ResizeObserver((entries) => {
        const { inlineSize, blockSize } = entries[0].borderBoxSize[0];
        handleHeaderWidth(inlineSize);
        handleHeaderHeight(blockSize);
      }).observe(ref);
    }
  }, [handleHeaderHeight, handleHeaderWidth]);

  useEffect(() => {
    if (headerWidth && headerHeight) {
      callback && callback({ width: headerWidth, height: headerHeight });
    }
  }, [callback, headerWidth, headerHeight]);

  return {
    ref: headerContainer,
    offsetHeight: headerContainer.current?.offsetHeight ?? 0,
    height: headerHeight,
  };
};

export default useHeaderContainer;
